export const lightTheme = {
  body: "#E2E2E2",
  text: "#363537",
  toggleBorder: "#FFF",
  heading: "#000000",
  gradient: "linear-gradient(#000000,#FEA029,#29FE43,#29FEE7,#FD0400,#ffffff )",
  header_bg: "#fff",
  hero_bg: "#fcf302",
  footer_bg: "#14b745",
  presalesmartswap_hover: "#000",
  footer_bg1: "#14b745",
  viewTrans: "#4740de",
  footerto_bg: "imgs/footer-1-top.png",
  popup: "#ffffff",
  connect_wallet: "#000",
  popup_after: "#fcf302",
  popup_modal: "#fff",
  input_boder: "#f0f0f0",
  primary_bg: "#fcf302",
  clone_border: "#9c9600",
  swapButtonColor: "#91dc27",
};

export const darkTheme = {
  body: "#363537",
  text: "#FAFAFA",
  heading: "#FFFFFF",
  toggleBorder: "#6B8096",
  gradient: "linear-gradient(#ffffff,#FD0400,#29FEE7,#29FE43,#FEA029,#000000)",
  header_bg: "#2f3239",
  hero_bg: "#2c2f36",
  primary_bg: "#7c7d80",
  footer_bg: "#000000",
  presalesmartswap_hover: "#fff",
  footer_bg1: "#404040",
  viewTrans: "#00f",
  footerto_bg: "imgs/folder-dark.png",
  popup: "#16191e",
  connect_wallet: "#5d6268",
  popup_after: "#171a1f",
  popup_modal: "#2c2f36",
  input_boder: "#52575d",
  clone_border: "#7c7d80",
  swapButtonColor: "#7c7d80",
};
