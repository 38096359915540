import { useEffect } from "react";

export const ConnectWallet = () => {
  return (
    <div className="hero__content--form">
      <form action="">
        <div className="input-group">
          <input id="inputID" type="text" placeholder="0" />

          <span className="BEP tokens">
            BEP20 <span className="icon-chevron-down"></span>
          </span>
          <span className="bSWAP tokens">
            <img src="imgs/form-input.png" alt="" />
            bSWAP v2 <span className="icon-chevron-down"></span>
          </span>

          <p className="bottomtext">
            Rate: 1 Bswap v2 = 0.0189881 ETH [$28.48]
            {/* <span className="primary-text">$28.48</span> */}
          </p>
        </div>
        <div className="exchange">
          <img src="imgs/exchange.png" alt="" />
        </div>
        <div className="input-group">
          <input id="inputID" type="text" placeholder="0" />

          <span className="BEP tokens">
            {" "}
            ERC20 <span className="icon-chevron-down"></span>
          </span>
          <span className="bSWAP tokens">
            <img src="imgs/ETH.png" alt="" />
            ETH <span className="icon-chevron-down"></span>
          </span>
          <p className="bottomtext">
            Rate: 1 ETH = 0.0190 bSWAP v2 [$28.51] | Estimated fees: ~ [
            <span className="primary-text">$5.37</span> ] | Reimbursement reward
            125.3645 [<span className="primary-text">$5.37</span>]
            {/* ETH |{" "}
            <span className="primary-text">125.36</span> Degen reward
            <i className="fas fa-question-circle" />
            <span className="dummypopup">
              Receive the best price on your trades from multiple aggregators{" "}
            </span> */}
          </p>
        </div>

        <div className="input-logos">
          <a href="#">
            <img src="imgs/form-input.png" alt="" />
          </a>
          <a href="#">
            <i className="fas fa-chevron-right" />
          </a>
          <a href="#">
            <img src="imgs/logos/smartswap.png" alt="" />
          </a>
          <a href="#">
            <i className="fas fa-chevron-right" />
          </a>
          <a href="#">
            <img src="imgs/logos/Layer-3666.png" alt="" />
          </a>
        </div>

        <div className="connect-wallet">
          <button type="submit" className="primary-btn" id="center-connect">
            connect wallet
          </button>
        </div>
      </form>
    </div>
  );
};
