import { useContext } from "react";
import { ThemeContext } from "../contexts/ThemeContext";

export const RightSidemenu = () => {
  const theme = useContext(ThemeContext);
  return (
    <div className="right-sidemenu">
      <a href="#" className="mainBtn">
        {" "}
        0X291a...H1RS <span className="dotspan"></span>
      </a>

      <p className="buy-degen primary-text">Buy Degen</p>

      <div className="swap-advanced">
        <p>SWAP advanced Settings</p>
        <div className="five-logos">
          <div className="active">
            <img src="imgs/icon-1.png" alt="" />
          </div>
          <div>
            <img src="imgs/icon-2.png" alt="" />
          </div>
          <div>
            <img src="imgs/icon-3.png" alt="" />
          </div>
          <div>
            <img src="imgs/icon-4.png" alt="" />
          </div>
          <div>
            <img src="imgs/icon-5.png" alt="" />
          </div>
        </div>
      </div>

      <div className="slippage-tolerance">
        <div className="content-slippage">
          <span>Slippage tolerance</span>
          <p>
            <span className="getvalue">.5%</span>
            <span className="slidechevron">
              <i className="fas fa-caret-down" />{" "}
            </span>
          </p>
        </div>

        <div className="values">
          <div className="selectvalue">
            <span>0.1%</span>
            <span className="active">0.5%</span>
            <span>1%</span>
            <span>3%</span>
          </div>
          <div className="custom-values">
            <input id="inputID" type="text" placeholder="Custom" />
          </div>
        </div>
      </div>

      <div className="Gas-price">
        <div className="content-slippage">
          <span>Gas Price</span>
          <p>
            <span className="getvalue">Fast (116 GWEI)</span>
            <span className="slidechevron">
              <i className="fas fa-caret-down" />
            </span>
          </p>
        </div>

        <div className="values">
          <div className="selectvalue">
            <span>91 standart</span>
            <span className="active">116 Fast</span>
            <span>130 Instant</span>
          </div>
          <div className="custom-values">
            <input id="inputID" type="text" placeholder="Custom" />
          </div>
        </div>
      </div>
      <div className="liquidiy-resource">
        <div className="liquid-content">
          <span>Liquidity resources</span>
          <p>
            <span className="getvalueresources">5</span>
            <span className="slidechevron-rersources">
              <i className="fas fa-caret-down" />
            </span>
          </p>
        </div>
        <div className="liquid-group">
          <div className="smart">
            <img src="imgs/liq-1.png" alt="" />
            Smart
          </div>
          <div className="inchlp">
            <img src="imgs/liq-2.png" alt="" />
            INCH LP V1.0
          </div>
          <div className="inchlp-mig">
            <img src="imgs/liq-2.png" alt="" />
            INCH LP V1.0 Migratar
          </div>
          <div className="inchlp-v1">
            <img src="imgs/liq-2.png" alt="" />
            INCH LP V1.1
          </div>
          <div className="AAVE">
            <img src="imgs/liq-5.png" alt="" />
            AAVE
          </div>
          <div className="AAVE-Liq">
            <img src="imgs/liq-5.png" alt="" />
            Aave Liquidatar
          </div>
        </div>
      </div>
      <div className="custom-token">
        <div className="custom-content">
          <span>
            Custom tokens
            <i className="fas fa-plus-circle" />
          </span>
          <p>
            <span className="">0</span>
            <span className="slidechevron-custom">
              <i className="fas fa-caret-down" />
            </span>
          </p>
        </div>
      </div>

      <div className="list-info">
        <ul>
          <li>
            <a href="#">Ledger</a>
          </li>
          <li>
            <a href="#">Pools</a>
          </li>
          <li>
            <a href="#">Farming</a>
          </li>
          <li>
            <a href="#">Read about slip page</a>
          </li>
          <li>
            <a href="#">Governance</a>
          </li>
        </ul>
      </div>

      <div className="library">
        <p className="slidelibrary">
          Library
          <span>
            <i className="fas fa-caret-down" />
          </span>
        </p>
        <ul className="dropdownlibrary">
          <li>
            <a href="#">Github</a>
          </li>
          <li>
            <a href="#">Whitepaper</a>
          </li>
          <li>
            <a href="#">Light Paper</a>
          </li>
          <li>
            <a href="#">Technical Documentaion</a>
          </li>
          <li>
            <a href="#">Pitch Deck</a>
          </li>
          <li>
            <a href="#">One Page</a>
          </li>
        </ul>
      </div>

      <div className="commuity">
        <p className="communityslide">
          Community
          <span className="">
            <i className="fas fa-caret-down" />
          </span>
        </p>
        <ul className="dropdowncommuity">
          <li>
            <a href="#">
              <i className="fab fa-twitter" /> Twitter
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fab fa-reddit" />
              Reddit
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fab fa-medium-m" />
              Medium
            </a>
          </li>
        </ul>
      </div>

      <div className="About">
        <p className="slideAbout">
          About
          <span className="">
            <i className="fas fa-caret-down" />
          </span>
        </p>
        <ul className="dropdownAbout">
          <li>
            <a href="#">About Us</a>
          </li>
          <li>
            <a href="#">Careers</a>
          </li>
          <li>
            <a href="#">Partner with Us</a>
          </li>
          <li>
            <a href="#">Privacy Policy</a>
          </li>
          <li>
            <a href="#">Terms Of Use</a>
          </li>
          <li>
            <a href="#">Cookie Policy</a>
          </li>
        </ul>
      </div>

      <div className="bottom-imgs">
        {/* <h1 id="mode" style={{ cursor: "pointer" }}>hjfdgsj</h1> */}
        <img
          id="mode"
          style={{ cursor: "pointer" }}
          src={
            !theme.isDark ? "imgs/bottom-imgs.png" : "imgs/bottom_imgs_dark.png"
          }
          alt=""
        />
      </div>

      <div className="footer-menu">
        <div>
          <img src="imgs/bottom-menu.png" alt="" />
        </div>
        <div className="copyright-menu">
          <p>&copy;2021 Degen Swap Aggregator.All right reserved</p>
        </div>
        <div className="powered-by-menu">
          <h2>Powered by Atom Foundation</h2>
          <p>
            Atom Foundation is a Decentralized Financial (DeFi) liquidity
            ecosystem powered by proprietary protocols which underlines IDO
            (Initial Dex Offerings) Auction technology, a NFT (Non-fungible
            token) marketplace with downside protection, multiple swap
            exchanges, cross network aggregation, as well as staking and pooling
            in a decentralized way.
          </p>
          <a href="#" className="donate">
            Donate Crypto to Charities
          </a>
        </div>
      </div>
    </div>
  );
};
