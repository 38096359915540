import { useEffect } from "react";

export const Ledger = () => {
  return (
    <div className="swap__ledger">
      <div className="swap__ledger__nav">
        <a href="#" className="primary-text">
          Ledger
        </a>
        <i className="fas fa-caret-down"></i>
        <div className="showviews">
          <i className="fas fa-stop gridView"></i>

          <i className="fas fa-th-list listView"></i>
        </div>
      </div>

      <div className="swap__ledger__container">
        <div className="swap__ledger__links">
          <a href="#" className="active">
            All
          </a>

          <a href="#" className="not primary-text">
            Completed
          </a>

          <a href="#" className="not primary-text">
            Pending
          </a>
        </div>

        <div className="swap__ledger__twodivs">
          <div className="swap__ledger__twodivs__sent">
            <h3 className="sentBEP">
              <span className="primary-text">Sent BEP20 from</span>{" "}
              <img src="imgs/icon-sentBeb.png" alt="" />
            </h3>

            <div className="transaction primary-text">
              <p>2 bSWAP v2 </p>
              <p>Feb 2. 2019, 9:21am PST</p>
              <i className="fas fa-check-circle"></i>
              <h4>Transaction Submitted</h4>
              <span> X0456c19d5A61AeA886E6D657EsEF8849565</span>
              <a href="#">View transaction</a>
            </div>
          </div>
          <div className="swap__ledger__twodivs__received">
            <h3 className="ReceivedERC20">
              <span className="primary-text">Received ERC20 from</span>{" "}
              <img src="imgs/icon-reciev-er20.png" alt="" />
            </h3>

            <div className="transaction primary-text">
              <p>1 ETH</p>
              <p>Feb 2. 2019, 9:21am PST</p>
              <i className="fas fa-check-circle"></i>
              <h4>Transaction Submitted</h4>

              <span> X0456c19d5A61AeA886E6D657EsEF8849565</span>
              <a href="#">View transaction</a>
            </div>
            <div
              className="transaction primary-text"
              style={{ display: "none" }}
            >
              <p>
                1 ETH <span>($389)</span>
              </p>
              <p>Feb 2. 2019, 9:21am PST</p>
              <i className="fas fa-check-circle"></i>
              <h4>Transaction Submitted</h4>

              <span> X0456c19d5A61AeA886E6D657EsEF8849565</span>
              <a href="#">View transaction</a>
            </div>
            <div
              className="transaction primary-text"
              style={{ display: "none" }}
            >
              <p>
                1 ETH <span>($389)</span>
              </p>
              <p>Feb 2. 2019, 9:21am PST</p>
              <i className="fas fa-check-circle"></i>
              <h4>Transaction Submitted</h4>

              <span> X0456c19d5A61AeA886E6D657EsEF8849565</span>
              <a href="#">View transaction</a>
            </div>
          </div>
          <div className="swap__ledger__twodivs__receivedreward">
            <h3 className="ReceivedERC20">
              <span className="primary-text">Received ERC20 from</span>{" "}
              <img src="imgs/icon-reciev-degen.png" alt="" />
            </h3>

            <div className="transaction primary-text">
              <p>125.36</p>
              <p>Feb 2. 2019, 9:21am PST</p>
              <i className="fas fa-check-circle"></i>
              <h4>Transaction Submitted</h4>

              <span> X0456c19d5A61AeA886E6D657EsEF8849565</span>
              <a href="#">View transaction</a>
            </div>
            <div
              className="transaction primary-text"
              style={{ display: "none" }}
            >
              <p>
                1 ETH <span>($389)</span>
              </p>
              <p>Feb 2. 2019, 9:21am PST</p>
              <i className="fas fa-check-circle"></i>
              <h4>Transaction Submitted</h4>

              <span> X0456c19d5A61AeA886E6D657EsEF8849565</span>
              <a href="#">View transaction</a>
            </div>
            <div
              className="transaction primary-text"
              style={{ display: "none" }}
            >
              <p>
                1 ETH <span>($389)</span>
              </p>
              <p>Feb 2. 2019, 9:21am PST</p>
              <i className="fas fa-check-circle"></i>
              <h4>Transaction Submitted</h4>

              <span> X0456c19d5A61AeA886E6D657EsEF8849565</span>
              <a href="#">View transaction</a>
            </div>
          </div>
        </div>
      </div>

      <div className="swap__ledger__container2 primary-text">
        <table className="tableLedger" style={{ display: "none" }}>
          <thead>
            <tr>
              <th>
                <span>sent</span>
              </th>
              <th>
                <span>receive</span>
              </th>
              <th>
                <span>reward</span>
              </th>
            </tr>
          </thead>
          <tbody className="thead2">
            <tr>
              <td>
                <span>time</span>
                <span>Asset</span>
                <span>Proof</span>
              </td>
              <td>
                <span>Time</span>
                <span>Asset</span>
                <span>Percentage</span>
                <span>Proof</span>
              </td>

              <td>
                <span>time</span>
                <span>Asset</span>
                <span>Proof</span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="dateSpan">
                  February 2. 2019. <br /> 9:21am PST
                </span>
                <span>2 bSWAP v2</span>
                <span>
                  <a href="#" className="viewTrans">
                    View transaction
                  </a>
                </span>
              </td>
              <td>
                <span className="dateSpan">
                  February 2. 2019. <br /> 9:21am PST
                </span>
                <span>1 ETH</span>
                <span>100%</span>
                <span>
                  <a href="#" className="viewTrans">
                    View transaction
                  </a>
                </span>
              </td>
              <td>
                <span className="dateSpan">
                  February 2. 2019. <br /> 9:21am PST
                </span>
                <span>125.36</span>
                <span>
                  <a href="#" className="viewTrans">
                    View transaction
                  </a>
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span className="dateSpan">
                  February 2. 2019. <br /> 9:21am PST
                </span>
                <span>1 BNB</span>
                <span>
                  <a href="#" className="viewTrans">
                    View transaction
                  </a>
                </span>
              </td>
              <td>
                <span className="dateSpan">
                  February 2. 2019. <br /> 9:21am PST
                </span>
                <span>1 ETH</span>

                <span>37.47%</span>
                <span>
                  <a href="#" className="viewTrans">
                    View transaction
                  </a>{" "}
                  <i className="fas fa-caret-down icondropdown"></i>
                </span>
                <ul className="dropdown">
                  <li>
                    <span></span>
                    <span>0.173ETH</span>
                    <span>17.3%</span>
                    <span>
                      <a href="#" className="viewTrans">
                        View transaction
                      </a>
                    </span>
                  </li>
                  <li>
                    <span></span>
                    <span>0.2017ETH</span>
                    <span>20.17%</span>
                    <span>
                      <a href="#" className="viewTrans">
                        View transaction
                      </a>
                    </span>
                  </li>
                  <li>
                    <span></span>
                    <span>0.6253ETH</span>
                    <span>62.53%</span>
                    <span>
                      Pending{" "}
                      <a href="#" style={{ color: "#fd0352" }}>
                        [Cancel and redeem to wallet]
                      </a>
                    </span>
                  </li>
                </ul>
              </td>

              <td>
                <span className="dateSpan">
                  February 2. 2019. <br /> 9:21am PST
                </span>
                <span>125.36</span>
                <span>
                  <a href="#" className="viewTrans">
                    View transaction
                  </a>
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span className="dateSpan">
                  February 2. 2019. <br /> 9:21am PST
                </span>
                <span></span>
                <span>
                  <a href="#" className="viewTrans">
                    View transaction
                  </a>
                </span>
              </td>
              <td>
                <span className="dateSpan">
                  February 2. 2019. <br /> 9:21am PST{" "}
                </span>
                <span>1 ETH</span>
                <span>100%</span>
                <span>
                  <a href="#" className="viewTrans">
                    View transaction
                  </a>
                </span>
              </td>
              <td>
                <span className="dateSpan">
                  February 2. 2019. <br /> 9:21am PST
                </span>
                <span></span>
                <span>
                  <a href="#" className="viewTrans">
                    View transaction
                  </a>
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="view2">
          <div className="sent">
            <div className="title">
              <h4 className="primary-text">Sent</h4>
            </div>
            <div className="row1">
              <span>time</span>
              <span>Asset</span>

              <span>Proof</span>
            </div>
            <div className="row2">
              <span>
                February 2. 2019.
                <br />
                9:21am PST
              </span>
              <span> 2 bSWAP v2</span>
              <span>
                <a href="#" className="viewTrans">
                  View transaction
                </a>
              </span>
            </div>
            <div className="row3">
              <span>
                February 2. 2019.
                <br />
                9:21am PST
              </span>
              <span> 1 BNB</span>
              <span>
                <a href="#" className="viewTrans">
                  View transaction
                </a>
              </span>
            </div>
            <div className="row4">
              <span>
                February 2. 2019.
                <br />
                9:21am PST
              </span>
              <span></span>
              <span>
                <a href="#" className="viewTrans">
                  View transaction
                </a>
              </span>
            </div>
          </div>

          <div className="receive">
            <div className="title">
              <h4 className="primary-text">Receive</h4>
            </div>
            <div className="row1">
              <span>time</span>
              <span>Asset</span>
              <span>Percentage</span>
              <span>Proof</span>
            </div>
            <div className="row2">
              <span>
                February 2. 2019.
                <br />
                9:21am PST
              </span>
              <span>1 ETH</span>
              <span>100%</span>
              <span>
                <a href="#" className="viewTrans">
                  View transaction
                </a>
              </span>
            </div>
            <div className="row3">
              <span>
                February 2. 2019.
                <br />
                9:21am PST
              </span>
              <span>1 ETH</span>
              <span>37.47%</span>
              <span>
                <a href="#" className="viewTrans">
                  View transaction
                </a>
                <div className="btn-drop">
                  <i className="fas fa-caret-down icondropdown"></i>
                </div>
              </span>

              <div className="dropdownrow">
                <div className="part1">
                  <span></span>
                  <span>0.173 ETH</span>
                  <span>17.30%</span>
                  <span>
                    <a href="#" className="viewTrans">
                      View transaction
                    </a>
                  </span>
                </div>
                <div className="part2">
                  <span></span>
                  <span>0.2017 ETH</span>
                  <span>20.17%</span>
                  <span>
                    <a href="#" className="viewTrans">
                      View transaction
                    </a>
                  </span>
                </div>
                <div className="part3">
                  <span></span>
                  <span>0.6253 ETH</span>
                  <span>62.53%</span>
                  <span>
                    <a href="#" className="viewTrans">
                      Pending{" "}
                      <span className="cancelredeem">
                        [Cancel and redeem to wallet]
                      </span>
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className="row4">
              <span>
                February 2. 2019.
                <br />
                9:21am PST
              </span>
              <span>1 ETH</span>
              <span>100%</span>
              <span>
                <a href="#" className="viewTrans">
                  View transaction
                </a>
              </span>
            </div>
          </div>

          <div className="reward">
            <div className="title">
              <h4 className="primary-text">Reward</h4>
            </div>
            <div className="row1">
              <span>time</span>
              <span>Asset</span>

              <span>Proof</span>
            </div>
            <div className="row2">
              <span>
                February 2. 2019.
                <br />
                9:21am PST
              </span>
              <span> 125.36</span>
              <span>
                <a href="#" className="viewTrans">
                  View transaction
                </a>
              </span>
            </div>
            <div className="row3">
              <span>
                February 2. 2019.
                <br />
                9:21am PST
              </span>
              <span> 125.36</span>
              <span>
                <a href="#" className="viewTrans">
                  View transaction
                </a>
              </span>
            </div>
            <div className="row4">
              <span>
                February 2. 2019.
                <br />
                9:21am PST
              </span>
              <span></span>
              <span>
                <a href="#" className="viewTrans">
                  View transaction
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
