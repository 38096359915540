import React, { useEffect, useState, useContext } from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { Container, Grid, Paper, useIsFocusVisible } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { MainCaousel } from "../components/MainCaousel";
import { RightSidemenu } from "../components/RightSidemenu";
import { Ledger } from "../components/Ledger";
import { Loader } from "../components/Loader";
import { useHistory } from "react-router-dom";
import { ConnectWallet } from "../components/ConnectWallet";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./../config/theme";
import { GlobalStyles } from "./../config/global";
import { ThemeContext } from "./../contexts/ThemeContext";
window.$("#connectwalletselect").niceSelect();
// document.querySelector('.owl-carousel .owl-nav button.owl-next span').innerHTML = `<i class="fas fa-chevron-right"></i>`

// document.querySelector('.owl-carousel .owl-nav button.owl-prev').innerHTML = `<i class="fas fa-chevron-left"></i>`
// let theme = '';
export const Home = () => {
  const [load, setLoad] = useState(false);
  const [count, setCount] = useState(0);
  const history = useHistory();
  const isFocused = useIsFocusVisible();
  const theme = useContext(ThemeContext);

  useEffect(() => {
    setTimeout(() => {
      window.$(
        ".owl-carousel .owl-nav button.owl-next span"
      ).html = `<i class="fas fa-chevron-right"></i>`;
      window.$(
        ".owl-carousel .owl-nav button.owl-prev"
      ).html = `<i class="fas fa-chevron-left"></i>`;
      document.body.onclick = (event) => {
        if (
          !(
            event &&
            event.target &&
            event.target.classList.contains("right-sidemenu")
          )
        ) {
          window.$(".right-sidemenu").removeClass("slide");
          document.documentElement.style.overflow = "visible";
        }
      };
      window.$(".right-sidemenu").on("click", (e) => {
        if (e.target.id === "mode") {
          theme.toggleTheme();
        }
        e.stopPropagation();
      });
    }, 100);

    swapDropdown();
    getRightMenu();
    connectWallet();
    slideAbout();
    slideCommunity();
    slideLibrary();
    addActiveValueGas();
    slideValue();
    addActiveValue();
    getTokens();
    showOptions();
    // addCtive();
    toggleBtn();
    showGirdList();
    dropdownTable();
    searchInput();
    // showpopup();
    dropdownView2();
    showLogos();
    addActive();
    const carouselWidth = window.$(".hero__content--logos").width();
    console.log(parseInt(carouselWidth));
    console.log(parseInt(carouselWidth) / 50);
    window.$(".owl-carousel").owlCarousel({
      loop: false,
      margin: 0,
      nav: true,
      dots: false,
      // center: true,
      items: parseInt(parseInt(carouselWidth) / 50),
      // responsive: {
      //   0: {
      //     items: 1,
      //   },
      //   600: {
      //     items: 12,
      //   },
      //   1300: {
      //     items: 20,
      //   },
      // },
    });
  }, []);

  const addActive = () => {
    let logoBrand = document.querySelectorAll(".five-logos > div");

    logoBrand.forEach((item) => {
      item.onclick = function () {
        for (var i = 0; i < logoBrand.length; i++) {
          logoBrand[i].classList.remove("active");
        }

        item.classList.add("active");
      };
    });
  };
  const showLogos = () => {
    var imgs = document.querySelectorAll(".hero__content--logos a img");

    var txts = document.querySelectorAll(".hero__content--logos a p");

    imgs.forEach((item, index) => {
      item.onclick = function () {
        if (window.matchMedia("(max-width: 600px)").matches) {
          for (var i = 0; i < imgs.length; i++) {
            txts[i].classList.remove("show");
          }
          if (txts[index].classList.contains("show")) {
            txts[index].classList.remove("show");
          } else {
            txts[index].classList.add("show");
          }
        }
      };
    });
  };
  const dropdownView2 = () => {
    var btndrop = document.querySelector(".btn-drop");

    var dropList = document.querySelector(".dropdownrow");

    btndrop.onclick = function () {
      if (dropList.classList.contains("show")) {
        dropList.classList.remove("show");
        document.querySelectorAll(".row3").forEach((item) => {
          item.style.height = "65px";
          item.style.alignItems = "center";
          if (window.matchMedia("(max-width: 500px)").matches) {
            item.style.height = "80px";
          }
          if (window.matchMedia("(max-width: 400px)").matches) {
            item.style.height = "90px";
          }
        });

        btndrop.classList.remove("rotate");
      } else {
        dropList.classList.add("show");
        document.querySelectorAll(".row3").forEach((item) => {
          item.style.height = "250px";
          item.style.alignItems = "flex-start";
          if (window.matchMedia("(max-width: 600px)").matches) {
            item.style.height = "450px";
          }
        });
        btndrop.classList.add("rotate");
      }
    };
  };
  const showpopup = () => {
    var icon = document.querySelector(
      ".hero__content--form form > div:nth-child(3) .bottomtext i"
    );

    var txt = document.querySelector(".dummypopup");

    icon.onclick = function () {
      if (txt.classList.contains("show")) {
        txt.classList.remove("show");
      } else {
        txt.classList.add("show");
      }
    };
  };
  const searchInput = () => {
    var myInput = document.querySelector(".popup .wrap .inputgroup input");

    var liItms = document.querySelectorAll(".popup .wrap .tokenlist ul li");

    var reqTxt = document.querySelectorAll(
      ".popup .wrap .tokenlist ul li span:nth-child(2)"
    );

    function myFunction() {
      var input, filter, ul, li, span, i, txtValue;
      input = document.querySelector(".popup .wrap .inputgroup input");
      filter = input.value.toUpperCase();
      ul = document.querySelector(".popup .wrap .tokenlist ul");
      li = ul.getElementsByTagName("li");
      for (i = 0; i < li.length; i++) {
        span = li[i].getElementsByTagName("span")[0];
        txtValue = span.textContent || span.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
        }
      }
    }

    myInput.addEventListener("keyup", myFunction);
  };
  const dropdownTable = () => {
    var icondropDown = document.querySelector(".icondropdown");

    var list = document.querySelector(".dropdown");

    icondropDown.onclick = function () {
      if (!list.classList.contains("show")) {
        list.classList.add("show");

        icondropDown.classList.add("rotate");
      } else {
        list.classList.remove("show");
        icondropDown.classList.remove("rotate");
      }
    };
  };
  const showGirdList = () => {
    var gridIcon = document.querySelector(".listView");
    var listIcon = document.querySelector(".gridView");

    var listDiv = document.querySelector(".swap__ledger__container");

    var gridDiv = document.querySelector(".swap__ledger__container2");

    gridIcon.onclick = function () {
      listDiv.classList.add("hide");

      gridDiv.classList.add("show");
      this.classList.add("active");
      listIcon.classList.remove("active");
      // listIcon.style.color = "#aaa";
      // this.style.color = "#fd0352";
    };

    listIcon.onclick = function () {
      listDiv.classList.remove("hide");

      gridDiv.classList.remove("show");
      this.classList.add("active");
      gridIcon.classList.remove("active");
      // this.style.color = "#fd0352";

      // gridIcon.style.color = "#aaa";
    };
  };
  const toggleBtn = () => {
    var onSpan = document.querySelectorAll(".onspan");
    var offSpan = document.querySelectorAll(".offspan");

    var inputOn = document.querySelectorAll(".checkon");

    var inputOff = document.querySelectorAll(".checkoff");

    onSpan.forEach((item, index) => {
      item.onclick = function () {
        inputOn[index].checked = true;
        inputOff[index].checked = false;
        item.style.backgroundColor = "#29f80c";
        offSpan[index].style.background = "none";
      };
    });
    offSpan.forEach((item, index) => {
      item.onclick = function () {
        inputOn[index].checked = false;
        inputOff[index].checked = true;
        item.style.backgroundColor = "#ababab";
        onSpan[index].style.background = "none";
      };
    });
  };
  const addCtive = () => {
    let listItems = document.querySelectorAll(".list-nav1 > li");
    listItems.forEach((item) => {
      item.onmouseenter = () => {
        for (var i = 0; i < listItems.length; i++) {
          listItems[i].classList.remove("active");
        }

        item.classList.add("active");
      };
    });
  };
  const showOptions = () => {
    var settingIcon = document.querySelector(".setting");
    var opts = document.querySelector(".options");

    settingIcon.onclick = function () {
      if (!opts.classList.contains("show")) {
        opts.classList.add("show");
      } else {
        opts.classList.remove("show");
      }
    };

    settingIcon.onmouseenter = function () {
      opts.classList.add("show");
    };

    //closing popup

    document.querySelector(".hero__content").onclick = function () {
      opts.classList.remove("show");
    };
    document.querySelector(".header").onclick = function (e) {
      if (!e.target.classList.contains("setting")) {
        opts.classList.remove("show");
      }

      console.log(e.target);
    };

    opts.onclick = function (e) {
      e.stopPropagation();
    };
  };
  const getTokens = () => {
    var tokens = document.querySelectorAll(".tokens");
    var popup = document.querySelector(".popup");
    var closeBtn = document.querySelector(".close");
    var listItems = document.querySelectorAll(".popup .wrap .tokenlist ul li");
    closeBtn.onclick = function () {
      popup.classList.remove("show");
      document.documentElement.style.overflow = "visible";
    };
    popup.onclick = function (e) {
      if (e.target.classList.contains("popup")) {
        popup.classList.remove("show");
        document.documentElement.style.overflow = "visible";
      }
    };

    tokens.forEach((item) => {
      item.onclick = function () {
        popup.classList.add("show");
        document.documentElement.style.overflow = "hidden";
        listItems.forEach((listelement) => {
          listelement.onclick = function (e) {
            for (var i = 0; i < listItems.length; i++) {
              listItems[i].classList.remove("active");
            }
            this.classList.add("active");
            item.innerHTML = `<img class = "jsimg" src ="${listelement.firstElementChild.getAttribute(
              "src"
            )}"/> <span class = "jsspan" >${
              listelement.firstElementChild.nextElementSibling.textContent
            }</span> <span class="icon-chevron-down"></span>`;

            popup.classList.remove("show");
            document.documentElement.style.overflow = "visible";
          };
        });
      };
    });
  };
  const addActiveValue = () => {
    let spanValue = document.querySelectorAll(
      ".slippage-tolerance .selectvalue > span"
    );

    let getValue = document.querySelector(".slippage-tolerance .getvalue");

    let myInput = document.querySelector(
      ".slippage-tolerance .values .custom-values input"
    );

    spanValue.forEach((item) => {
      item.onclick = function () {
        for (var i = 0; i < spanValue.length; i++) {
          spanValue[i].classList.remove("active");
        }

        item.classList.add("active");

        getValue.textContent = item.textContent;
      };
    });

    myInput.oninput = function () {
      if (myInput.value !== "") {
        getValue.textContent = myInput.value + "%";
      } else {
        getValue.textContent = document.querySelector(
          ".content-slippage .selectvalue > span.active"
        ).textContent;
      }
    };
  };
  const slideValue = () => {
    let chevron = document.querySelectorAll(".slidechevron");

    let valueList = document.querySelectorAll(".values");

    let show = false;

    chevron.forEach((item, index) => {
      item.onclick = function () {
        if (!valueList[index].classList.contains("slide")) {
          valueList[index].classList.add("slide");
          item.classList.add("rotate");
          show = true;
        } else {
          valueList[index].classList.remove("slide");
          item.classList.remove("rotate");
          show = false;
        }
      };
    });
  };
  const addActiveValueGas = () => {
    let spanValue = document.querySelectorAll(".Gas-price .selectvalue > span");

    let getValue = document.querySelector(".Gas-price .getvalue");

    let myInput = document.querySelector(
      ".Gas-price .values .custom-values input"
    );

    spanValue.forEach((item) => {
      item.onclick = function () {
        for (var i = 0; i < spanValue.length; i++) {
          spanValue[i].classList.remove("active");
        }

        item.classList.add("active");

        getValue.textContent =
          item.textContent.split(" ").reverse().join(" ") + " GEWI";
      };
    });

    myInput.oninput = function () {
      if (myInput.value !== "") {
        getValue.textContent = myInput.value;
      } else {
        getValue.textContent = document.querySelector(
          ".Gas-price .selectvalue > span.active"
        ).textContent;
      }
    };
  };
  const slideLibrary = () => {
    let slidelibrary = document.querySelector(".slidelibrary");

    let dropdownLibrary = document.querySelector(".dropdownlibrary");

    let show = false;

    function slide() {
      if (!show) {
        dropdownLibrary.classList.add("slide");
        slidelibrary.firstElementChild.classList.add("rotate");

        show = true;
      } else {
        dropdownLibrary.classList.remove("slide");
        slidelibrary.firstElementChild.classList.remove("rotate");

        show = false;
      }
    }

    slidelibrary.addEventListener("click", slide);
  };
  const slideCommunity = () => {
    let communityslide = document.querySelector(".communityslide");

    let dropdowncommuity = document.querySelector(".dropdowncommuity");

    let show = false;

    function slide() {
      if (!show) {
        dropdowncommuity.classList.add("slide");
        communityslide.firstElementChild.classList.add("rotate");

        show = true;
      } else {
        dropdowncommuity.classList.remove("slide");
        communityslide.firstElementChild.classList.remove("rotate");

        show = false;
      }
    }

    communityslide.addEventListener("click", slide);
  };

  const slideAbout = () => {
    let Aboutslide = document.querySelector(".slideAbout");

    let dropdownAbout = document.querySelector(".dropdownAbout");

    let show = false;

    function slide() {
      if (!show) {
        dropdownAbout.classList.add("slide");
        Aboutslide.firstElementChild.classList.add("rotate");

        show = true;
      } else {
        dropdownAbout.classList.remove("slide");
        Aboutslide.firstElementChild.classList.remove("rotate");

        show = false;
      }
    }

    Aboutslide.addEventListener("click", slide);
  };

  const getRightMenu = () => {
    let btntomenu = document.querySelectorAll(".btntomenu");

    let mainBtn = document.querySelector(".mainBtn");
    let menuRight = document.querySelector(".right-sidemenu");

    btntomenu.forEach((item) => {
      item.onclick = function (event) {
        event.stopPropagation();
        menuRight.classList.add("slide");

        document.documentElement.style.overflow = "hidden";
      };
    });

    mainBtn.onclick = function () {
      document.documentElement.style.overflow = "visible";

      menuRight.classList.remove("slide");
    };
  };

  const connectWallet = () => {
    let connectwallet = document.querySelector(".connectwallet");
    let centerConnect = document.querySelector("#center-connect");

    let login = document.querySelector(".login");

    let dotspan = document.querySelector(".dotspan");

    let connectpopup = document.querySelector(".connectpopup");

    let presalesmartswap = document.querySelector(".presalesmartswap");

    connectwallet.onclick = function () {
      connectpopup.classList.add("show");

      setTimeout(function () {
        connectpopup.classList.remove("show");
        connectwallet.classList.add("hide");
        centerConnect.classList.add("swap-btn");
        centerConnect.innerHTML = "Swap";
        login.classList.add("show");

        presalesmartswap.style.display = "none";
        dotspan.classList.add("active");
      }, 1200);
    };
  };

  const swapDropdown = () => {
    let pageY;
    let closeCaret = document.querySelector(" .swap__ledger__nav i");
    if (typeof closeCaret != "undefined" && closeCaret != null) {
      let linkLegder = closeCaret.previousElementSibling;
      let parentCaret = closeCaret.parentElement;
      let contSec = closeCaret.parentElement.nextElementSibling;
      let showViews = document.querySelector(".showviews");
      let container2 = document.querySelector(".swap__ledger__container2");
      let container1 = document.querySelector(".swap__ledger__container");
      let listIcon = document.querySelector(".listView");
      let gridIcon = document.querySelector(".gridView");
      closeCaret.addEventListener("click", slide);

      function slide() {
        if (!contSec.classList.contains("slide")) {
          parentCaret.style.marginBottom = "2.5rem";
          contSec.classList.remove("true");
          contSec.classList.add("slide");
          closeCaret.classList.add("rotate");

          document.documentElement.style.scrollBehavior = "smooth";
          showViews.classList.add("show");
          // gridIcon.style.color = "#fd0352";
          // listIcon.style.color = "#aaa";
        } else {
          parentCaret.style.marginBottom = "0";
          contSec.classList.remove("slide");
          closeCaret.classList.remove("rotate");
          container2.classList.remove("show");
          container1.classList.remove("hide");
          document.documentElement.style.scrollBehavior = "auto";
          showViews.classList.remove("show");
          // listIcon.style.color = "#aaa";
        }
      }
      return true;
    }
  };
  const onLoader = (load) => {
    setLoad(load);
    setTimeout(() => {
      // alert(load)
      setLoad(false);
      history.push("/notfound");
    }, 5000);
  };

  // const toggleTheme = () => {
  //     // if the theme is not light, then set it to dark
  //     if (theme === 'light') {

  //         // setTheme('dark');
  //         // otherwise, it should be light
  //     } else {
  //         // setTheme('light');
  //     }
  // }

  return (
    <div>
      <header className="">
        <Header
        //  onpress={() => toggleTheme()}
        />

        <div className="hero">
          <div className="hero__content">
            <div className="hero__content--h2">
              <h2>
                {" "}
                <span className="primary-text">
                  Receive the best price on your trades from multiple
                  decentraliz exchanges
                </span>
              </h2>
            </div>

            <MainCaousel />

            <ConnectWallet />

            <div className="beforeUsing">
              <p className="primary-text">
                0.1% service fee <i className="fas fa-question-circle"></i>
                <span className="popupdgen">
                  80% of fees support DEGEN liquid while 20% supports DEGEN
                  development
                </span>{" "}
              </p>
              <p className="primary-text">100% gas and fee reimbursement</p>
              <p className="btntomenu primary-text">
                <i className="fas fa-cog" style={{ verticalAlign: "-6px" }}></i>{" "}
                Transaction Settings
              </p>
            </div>
          </div>

          <Ledger />
        </div>
      </header>
      <Footer />

      <div className="popup">
        <div className="header">
          <div className="header__logo">
            <img src="imgs/right-logo.png" alt="" />
          </div>
          <div className="header__info">
            <a href="#">ifo live</a>
          </div>

          <div className="header__title">
            <h1>One-click cross-chain liquidity aggregator</h1>
          </div>
          <div className="header__control">
            <form action="" method="GET">
              <img src="imgs/cntl-logo.png" alt="" />
              <button className="connectwallet" type="submit">
                connect wallet
              </button>
              <a className="setting " href="#">
                <i className="fas fa-cog"></i>
              </a>

              <div className="options ">
                <h3>Transaction Settings </h3>
                <h4>
                  Slippage tolerance <i className="fas fa-question-circle"></i>
                </h4>
                <select
                  name="selectbox"
                  id="connectwalletselect"
                  style={{ display: "none" }}
                >
                  <option value=""></option>
                  <option value=".1%">.1%</option>
                  <option value=".5%">.5%</option>
                  <option value="1%">1%</option>
                  <option value=".50%">.50%</option>
                </select>
                <div className="nice-select" tabindex="0">
                  <span className="current"></span>
                  <ul className="list">
                    <li data-value="" className="option selected"></li>
                    <li data-value=".1%" className="option">
                      .1%
                    </li>
                    <li data-value=".5%" className="option">
                      .5%
                    </li>
                    <li data-value="1%" className="option">
                      1%
                    </li>
                    <li data-value=".50%" className="option">
                      .50%
                    </li>
                  </ul>
                </div>
                <p className="deadline">
                  Transaction deadline <i className="fas fa-question-circle" />
                </p>
                <input
                  id="inputID"
                  type="text"
                  placeholder="20"
                  className="inputmnts"
                />{" "}
                <span className="mnts">minutes</span>
                <p className="intsett">interface settings</p>
                <div className="toggleexpert">
                  <p>
                    Toogle expert mode <i className="fas fa-question-circle" />
                  </p>
                  <div className="togglebutton">
                    <span>
                      <input
                        id="inputID"
                        type="checkbox"
                        className="checkon"
                        name="on"
                      />
                      <input
                        id="inputID"
                        type="checkbox"
                        className="checkoff"
                        name="off"
                      />
                      <span className="onspan">on</span>
                      <span className="offspan">off</span>
                    </span>
                  </div>
                </div>
                <div className="toggldark">
                  <p>Toogle dark mode</p>
                  <div className="togglebutton">
                    <span>
                      <input
                        id="inputID"
                        type="checkbox"
                        className="checkon"
                        name=""
                      />

                      <input
                        id="inputID"
                        type="checkbox"
                        className="checkoff"
                        name=""
                      />
                      <span className="onspan">on</span>
                      <span className="offspan">off</span>
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="wrap">
          <div className="uppper">
            <p className="select-token">
              Select token <i className="fas fa-question-circle" />{" "}
              <span className="popupspan">Search for tokens </span>
            </p>
            <div className="dropdownERC">
              <ul>
                <li>ERC20</li>
              </ul>
              <span className="icon-chevron-down"></span>
            </div>
            <div className="close">
              <img src="imgs/xshape-1.png" width="14" alt="" />
            </div>
          </div>
          <div className="inputgroup">
            <input
              id="inputID"
              type="text"
              placeholder="Search name or paste address"
            />
          </div>
          <div className="tokenNames">
            <span>Token name</span>
            <i className="fas fa-arrow-down" />
          </div>

          <div className="tokenlist">
            <ul>
              <li>
                <img src="imgs/bnb.png" alt="BNB" />
                <span>BNB</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/wbnb.png" alt="wbnb" />
                <span>WBNB</span>
                <span className="dash">-</span>
              </li>

              <li>
                <img src="imgs/7UP.png" alt="7UP" />
                <span>7UP</span>
                <span className="dash">-</span>
              </li>

              <li>
                <img src="imgs/ADA.png" alt="ADA" />
                <span>ADA</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/ANKR.png" alt="ANKR" />
                <span>ANKR</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/ANY.png" alt="ANY" />
                <span>ANY</span>
                <span className="dash">-</span>
              </li>

              <li>
                <img src="imgs/anyUNI.png" alt="anyUNI" />
                <span>anyUNI</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/atom.png" alt="ATOM" />
                <span>ATOM</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/BAI.png" alt="BAI" />
                <span>BAI</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/bake.png" alt="bake" />
                <span>BAKE</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/band.png" alt="BAND" />
                <span>BAND</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/bsch.png" alt="BSCH" />
                <span>BSCH</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/best.png" alt="BEST" />
                <span>BEST</span>
                <span className="dash">-</span>
              </li>

              <li>
                <img src="imgs/BFI.png" alt="BFI" />
                <span>BFI</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/bhc.png" alt="BHC" />
                <span>BHC</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/bifi.png" alt="bifi" />
                <span>BIFI</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/broobe.png" alt="bROOBEE" />
                <span>bROOBEE</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/BSC.png" alt="BSC" />
                <span>BSC</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/bswap.png" alt="BSWAP" />
                <span>BSWAP</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/BSWAP1.png" alt="BSWAP1" />
                <span>BSWAP</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/BAI.png" alt="BTCB" />
                <span>BTCB</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/burger.png" alt="BURGER" />
                <span>BURGER</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/BUSD.png" alt="bifi" />
                <span>BUSD</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/BZAR.png" alt="bifi" />
                <span>BZAR</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/CAKE.png" alt="bifi" />
                <span>CAKE</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/CAN.png" alt="bifi" />
                <span>CAN</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/CBIX.png" alt="bifi" />
                <span>CBIX</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/COCO.png" alt="bifi" />
                <span>COCO</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/COLA.png" alt="bifi" />
                <span>COLA</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/CRBNB.png" alt="bifi" />
                <span>CRBNB</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/CRBUSD.png" alt="bifi" />
                <span>CRBUSD</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/CRBUSD.png" alt="bifi" />
                <span>CREAM</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/CTK.png" alt="bifi" />
                <span>CTK</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/DAI.png" alt="bifi" />
                <span>DAI</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/DEGEN.png" alt="bifi" />
                <span>DEGEN</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/DOS.png" alt="bifi" />
                <span>DOS</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/DOT.png" alt="bifi" />
                <span>DOT</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/DRUGS.png" alt="bifi" />
                <span>DRUGS</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/DUCK.png" alt="bifi" />
                <span>DUCK</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/EARTH.png" alt="bifi" />
                <span>EARTH</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/EOS.png" alt="bifi" />
                <span>EOS</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/ETH1.png" alt="bifi" />
                <span>ETH</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/FOR.png" alt="bifi" />
                <span>FOR</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/HARD.png" alt="bifi" />
                <span>HARD</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/INNBC.png" alt="bifi" />
                <span>INNBC</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/JEO.png" alt="bifi" />
                <span>JEO</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/JNTRB.png" alt="bifi" />
                <span>JNTR/B</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/JULB.png" alt="bifi" />
                <span>JULb</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/kogeb.png" alt="bifi" />
                <span>KOJE</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/LFI.png" alt="bifi" />
                <span>LFI</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/LGX.png" alt="bifi" />
                <span>LGX</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/LINK.png" alt="bifi" />
                <span>LINK</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/LOVE.png" alt="bifi" />
                <span>LOVE</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/LTC.png" alt="bifi" />
                <span>LTC</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/MLIK.png" alt="bifi" />
                <span>MILK</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/MOON.png" alt="bifi" />
                <span>MOON</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/NYA.png" alt="bifi" />
                <span>NYA</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/ONT.png" alt="bifi" />
                <span>ONT</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/PEACH.png" alt="bifi" />
                <span>PEACH</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/PROM.png" alt="bifi" />
                <span>PROM</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/PROBEL.png" alt="bifi" />
                <span>PROPEL</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/pumbk.png" alt="bifi" />
                <span>PUMK</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/RABBIT.png" alt="bifi" />
                <span>RABBIT</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/RAVEN.png" alt="bifi" />
                <span>RAVEN</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/SPARTQA.png" alt="bifi" />
                <span>SPARTA</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/STM.png" alt="bifi" />
                <span>STM</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/SYRUP.png" alt="bifi" />
                <span>SYRUP</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/THUGS.png" alt="bifi" />
                <span>THUGS</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/TUNA.png" alt="bifi" />
                <span>TUNA</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/TWT.png" alt="bifi" />
                <span>TWT</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/USDT.png" alt="bifi" />
                <span>USDT</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/VIDT.png" alt="bifi" />
                <span>VIDT</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/VTBC.png" alt="bifi" />
                <span>VTBC</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/XRP.png" alt="bifi" />
                <span>XRP</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/XTZ.png" alt="bifi" />
                <span>XTZ</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/XVS.png" alt="bifi" />
                <span>XVS</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/YFII.png" alt="bifi" />
                <span>YFII</span>
                <span className="dash">-</span>
              </li>
              <li>
                <img src="imgs/ZEC.png" alt="bifi" />
                <span>ZEC</span>
                <span className="dash">-</span>
              </li>
            </ul>
          </div>
          <div className="havetrouble">
            <a href="#">Having trouble finding a token ?</a>
          </div>
        </div>
      </div>

      <RightSidemenu />

      <Loader />

      {/* <div className="connectpopup">
          <div className="content">
              Connectiong Your Wallet ...
           </div>
      </div> */}
    </div>
  );
};
