import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  *,
  *::after {
   // background :${({ theme }) => theme.header_bg}; 
  }
  *::before {
    //box-sizing: border-box;
   // background :${({ theme }) => theme.header_bg}; 
  }

  body {
     background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    
  }
  
  p,h1,h2,h3,h4,h5,h6 {
    color: ${({ theme }) => theme.heading};
 
  }
  .header {
    display:flex; 
    align-items:center; 
    background :${({ theme }) => theme.header_bg}; 
    flex-wrap:wrap; 
    justify-content:space-between;
  }
  .hero { 
    background :${({ theme }) => theme.hero_bg};
 }
footer .footerbottom {
  background :${({ theme }) => theme.footer_bg};
}
.presalesmartswap {
  color:${({ theme }) => theme.presalesmartswap_hover};
}
.swap__ledger__nav a {
  color: ${({ theme }) => theme.heading};
}
.swap__ledger__links a.not{
  color: ${({ theme }) => theme.heading};
}
.swap__ledger__twodivs h3 {
  color: ${({ theme }) => theme.heading};
}
.swap__ledger__twodivs .transaction > p:first-of-type {
  color: ${({ theme }) => theme.heading};
}
.swap__ledger__twodivs .transaction > p:nth-of-type(2) {
  color: ${({ theme }) => theme.heading};
}
.swap__ledger__twodivs .transaction > span {
  color: ${({ theme }) => theme.heading};
}
.swap__ledger__twodivs .transaction i {
  color: ${({ theme }) => theme.heading};
}
.swap__ledger__twodivs .transaction {
 // color: ${({ theme }) => theme.heading};
  border-bottom:1px solid ${({ theme }) => theme.heading};
}

table, th, td {
  border: 1px solid red;
}
.swap__ledger__container2 .view2 .receive .row1,.swap__ledger__container2 .view2 .reward .row1,.swap__ledger__container2 .view2 .sent .row1 {
   border-top:2px solid ${({ theme }) => theme.heading}; 
   border-bottom:2px solid ${({ theme }) => theme.heading}; 
}
  .swap__ledger__container2 .view2 .receive .row1,.swap__ledger__container2 .view2 .receive .row2,.swap__ledger__container2 .view2 .receive .row3,.swap__ledger__container2 .view2 .receive .row4,.swap__ledger__container2 .view2 .reward .row1,.swap__ledger__container2 .view2 .reward .row2,.swap__ledger__container2 .view2 .reward .row3,.swap__ledger__container2 .view2 .reward .row4,.swap__ledger__container2 .view2 .sent .row1,.swap__ledger__container2 .view2 .sent .row2,.swap__ledger__container2 .view2 .sent .row3,.swap__ledger__container2 .view2 .sent .row4 {
      border-right:1px solid ${({ theme }) => theme.heading};  
  }  
  .swap__ledger__container2 .view2 .receive .row2,.swap__ledger__container2 .view2 .receive .row3,.swap__ledger__container2 .view2 .receive .row4,.swap__ledger__container2 .view2 .reward .row2,.swap__ledger__container2 .view2 .reward .row3,.swap__ledger__container2 .view2 .reward .row4,.swap__ledger__container2 .view2 .sent .row2,.swap__ledger__container2 .view2 .sent .row3,.swap__ledger__container2 .view2 .sent .row4 {
    border-bottom:1px solid ${({ theme }) => theme.heading}; 
  }
  .mainBtn ,.swap-advanced p {
    color: ${({ theme }) => theme.heading};
  }
  .right-sidemenu {
    background :${({ theme }) => theme.header_bg}; 
  }
  .Gas-price .content-slippage,.slippage-tolerance .content-slippage {color: ${({
    theme,
  }) => theme.heading};}
.Gas-price .content-slippage span,.slippage-tolerance .content-slippage span {color: ${({
  theme,
}) => theme.heading};}
.Gas-price .content-slippage span:nth-child(1),.slippage-tolerance .content-slippage span:nth-child(1) {color: ${({
  theme,
}) => theme.heading};}
.Gas-price .content-slippage p span:nth-child(1),.slippage-tolerance .content-slippage p span:nth-child(1) {color: ${({
  theme,
}) => theme.heading};}
.Gas-price .content-slippage p span:nth-child(2),.slippage-tolerance .content-slippage p span:nth-child(2) {color: ${({
  theme,
}) => theme.heading};}
.Gas-price .content-slippage p span:nth-child(2).rotate,.slippage-tolerance .content-slippage p span:nth-child(2).rotate {color: ${({
  theme,
}) => theme.heading};}

.liquidiy-resource .liquid-content {color: ${({ theme }) => theme.heading};}
.liquidiy-resource .liquid-content > span {color: ${({ theme }) =>
  theme.heading};}
.liquidiy-resource .liquid-content p span {color: ${({ theme }) =>
  theme.heading};}
.liquidiy-resource .liquid-content p span:nth-child(1) {color: ${({ theme }) =>
  theme.heading};}
.liquidiy-resource .liquid-content p span:nth-child(2) {color: ${({ theme }) =>
  theme.heading};}  
.powered-by-menu h2 {color: ${({ theme }) => theme.heading};}

.powered-by-menu p ,.copyright-menu p {color: ${({ theme }) => theme.heading};}

.powered-by-menu .donate ,.list-info ul li a {color: ${({ theme }) =>
  theme.heading};}

.custom-token .custom-content, .owl-carousel .owl-nav button.owl-next span {color: ${({
  theme,
}) => theme.heading};}
.custom-token .custom-content > span {color: ${({ theme }) => theme.heading};}
.custom-token .custom-content > span i {color: ${({ theme }) => theme.heading};}
.custom-token .custom-content p span {color: ${({ theme }) => theme.heading};}
.custom-token .custom-content p span:nth-child(1) {color: ${({ theme }) =>
  theme.heading};}
.custom-token .custom-content p span:nth-child(2) {color: ${({ theme }) =>
  theme.heading};}
.library .dropdownlibrary, .library , .library > p , .library > p span ,  .library > p span.rotate  {color: ${({
  theme,
}) => theme.heading};}
.library .dropdownlibrary.slide, .About ,.About > p ,.About > p span , .About > p span.rotate , .About .dropdownAbout , .About .dropdownAbout.slide , .About .dropdownAbout a , .About .dropdownAbout a i  {color: ${({
  theme,
}) => theme.heading};}
.library .dropdownlibrary a, .commuity, .commuity > p, .commuity > p span, .commuity > p span.rotate,.commuity .dropdowncommuity, .commuity .dropdowncommuity.slide, .commuity .dropdowncommuity a, .commuity .dropdowncommuity a i {color: ${({
  theme,
}) => theme.heading};}
// .liquidiy-resource .liquid-group > div {
//   color: ${({ theme }) => theme.heading};
// }
.liquidiy-resource .liquid-group .AAVE-Liq {
  color: ${({ theme }) => theme.heading};
}
#blackimg {
  background: ${({ theme }) => theme.footer_bg1};
}
.swap__ledger__container2 .tableLedger tbody tr td .viewTrans{
  color: ${({ theme }) => theme.viewTrans};
}
footer .footertop {
  background-color:${({ theme }) => theme.hero_bg};
}
footer .footertop::before {
  background-image:url(${({ theme }) => theme.footerto_bg});
}
.fa-chevron-left:before {
  color: ${({ theme }) => theme.heading};
}
.owl-carousel .owl-nav button.owl-next i {
  color: ${({ theme }) => theme.heading};
}
.hero__content--logos a p , .hero__content--form .input-logos i {
  color: ${({ theme }) => theme.heading};
}
.hero__content--form{
  background: ${({ theme }) => theme.popup};
}
.hero__content--form .connect-wallet {
  background-color:${({ theme }) => theme.connect_wallet}; 
}
.hero__content--form .input-group input {
  background: ${({ theme }) => theme.popup};
}
.hero__content--form .input-group .BEP , .hero__content--form .input-group .bSWAP{
  color: ${({ theme }) => theme.heading};
}
.popup::after { 
  background-color: ${({ theme }) => theme.popup_after};
  
}
.popup .wrap {
  background: ${({ theme }) => theme.popup_modal};
}
.popup .wrap .inputgroup input{
  background: ${({ theme }) => theme.popup_modal};
}
.popup .wrap .tokenlist ul li , .popup .wrap .havetrouble a{
  color:${({ theme }) => theme.heading};
}
#inputID::placeholder {
  color:${({ theme }) => theme.heading};
  opacity: 1;
}
#inputID::-webkit-input-placeholder {
  color:${({ theme }) => theme.heading};
  opacity: 1;
}
input[type="text"] , input[type="checkbox"] {    
  color:${({ theme }) => theme.heading};
}

.subdomain .connect-wallet{
  background:transparent;
}

.subdomain .primary-btn::before{
  background: ${({ theme }) => theme.footer_bg};
}
.subdomain .swap-btn::before{
  background: ${({ theme }) => theme.swapButtonColor};
}
.subdomain.light .primary-text{
  color:${({ theme }) => theme.hero_bg} !important;
  filter: brightness(0.3) !important;
}
.subdomain .primary-text{
  color:#fff !important;
}
.subdomain.light .hero__content .beforeUsing p:not(:last-child)::after{
  background-color: ${({ theme }) => theme.footer_bg} !important;
}
.subdomain .hero__content .beforeUsing p:not(:last-child)::after{
  background-color: #fff !important;
}
.subdomain.light footer .footerbottom .list-nav1 > li:hover a{
  color: ${({ theme }) => theme.footer_bg} !important;
  filter: brightness(2);
}
.subdomain footer .footerbottom .list-nav1 > li:hover a{
  color: #fff !important;
  filter: brightness(0.8);
}
.subdomain.light footer .column{
  background-color: ${({ theme }) => theme.footer_bg} !important;
  filter: brightness(2);
}
.subdomain footer .column{
  background-color: #fff !important;
  filter: brightness(0.8);
}
.subdomain .swap-advanced .five-logos > div, .subdomain .liquidiy-resource .liquid-group > div{
  background-color: ${({ theme }) => theme.primary_bg};
  border-color: ${({ theme }) => theme.primary_bg};
  filter: brightness(1.5);
}
.subdomain .liquidiy-resource .liquid-group .AAVE-Liq{
  background: none;
}
.subdomain .swap-advanced .five-logos > div.active{
  background-color: #fff;
  border-color: #fff;
}

.subdomain.light .swap-advanced .five-logos > div{
  border-color: ${({ theme }) => theme.footer_bg};
  background-color: transparent;
}
.subdomain.light .liquidiy-resource .liquid-group > div{
  border-color: ${({ theme }) => theme.footer_bg};
  background-color: ${({ theme }) => theme.footer_bg};
}
.subdomain .Gas-price .values .selectvalue, .subdomain .slippage-tolerance .values .selectvalue, .subdomain .Gas-price .values .custom-values input, .subdomain .slippage-tolerance .values .custom-values input{
  border-color: ${({ theme }) => theme.primary_bg};
  background-color: transparent;
}
.subdomain .Gas-price .values .selectvalue span, .subdomain .slippage-tolerance .values .selectvalue span{
  color:#ffffff
}
.subdomain .Gas-price .values .selectvalue span.active, .subdomain .slippage-tolerance .values .selectvalue span.active{
  color:#000000
}
.subdomain .Gas-price .values .selectvalue span.active, .subdomain .slippage-tolerance .values .selectvalue span.active{
  background-color: ${({ theme }) => theme.primary_bg};
  filter: brightness(1.5);
}
.subdomain.light .Gas-price .values .selectvalue, .subdomain.light .slippage-tolerance .values .selectvalue, .subdomain.light .Gas-price .values .custom-values input, .subdomain.light .slippage-tolerance .values .custom-values input{
  border-color: ${({ theme }) => theme.footer_bg};
  background-color: transparent;
}
.subdomain.light .Gas-price .values .selectvalue span, .subdomain.light .slippage-tolerance .values .selectvalue span{
  color:#000000 
}
.subdomain.light .Gas-price .values .selectvalue span.active, .subdomain.light .slippage-tolerance .values .selectvalue span.active{
  background-color: ${({ theme }) => theme.footer_bg};
  filter: brightness(1.5);
}

.subdomain.light .swap-advanced .five-logos > div.active{
  background-color: ${({ theme }) => theme.footer_bg} !important;
}
.subdomain.light .liquidiy-resource .liquid-group .AAVE-Liq{
  background: none;
}
.subdomain.light .right-sidemenu.slide{
  background: none;
}
.subdomain.light .right-sidemenu:before{
  background-color: ${({ theme }) => theme.primary_bg} !important;
  filter: brightness(1.2);
  content: "";
  position: fixed;
  width: inherit;
  height: inherit;
  right: 0;
  top: 0;
  z-index: -1;
  left: auto;
  transition: 0.3s;
  right: -600px;
}
.subdomain.light .right-sidemenu.slide:before{
  right: 0;
  left: auto;
}
.subdomain .list-info ul li a:hover{
color:#fff;
}
.subdomain.light .list-info ul li a:hover{
color:${({ theme }) => theme.footer_bg}
}
.subdomain .mainBtn span, .subdomain .header__control .right-menu-btn .dotspan{
  background-color: #fff;
}
.subdomain.light .mainBtn span, .subdomain.light .header__control .right-menu-btn .dotspan{
  background-color: ${({ theme }) => theme.footer_bg} !important;
}

.hero__content--form .input-group input, .popup .wrap .inputgroup, .dropdownERC  {
  border: 2px solid ${({ theme }) => theme.input_boder} !important;
}
.subdomain.light .swap__ledger__links a.active{
  color:${({ theme }) => theme.footer_bg}
}

.showviews.show{
  color:${({ theme }) => theme.hero_bg} !important;
  filter: brightness(0.3) !important;
}
.showviews.show .active{
  color:${({ theme }) => theme.footer_bg}
}
body{
  font-family: "Comic";
}
// #CloneForm{
//   background: ${({ theme }) => theme.hero_bg} !important;
// }
.input-data, .color-box, .clone-line{
  background: ${({ theme }) => theme.clone_border} !important;
}
.input, .md-radio label:before{
  border-color: ${({ theme }) => theme.clone_border} !important;
}
// primary_bg
<<<<<<< src/config/global.js
 .bottom-imgs {
  cursor: pointer;
}
 

 `;
