import { useContext, useState } from "react";
import { ThemeContext } from "../contexts/ThemeContext";
import CloneForm from "./CloneForm";
import About from "./About";
export const Footer = () => {
  const theme = useContext(ThemeContext);
  const [clonePopup, setClonePopUp] = useState(false);
  const [aboutPopup, setAboutPopUp] = useState(
    theme.isSubdomain ? false : true
  );
  const [prevCloneData, setPrevCloneData] = useState();

  const setCloneData = (data) => {
    theme.updateCloneData(data, true);
    setPrevCloneData(data);
    setClonePopUp(false);
    document.getElementsByTagName("body")[0].style.fontFamily =
      data.fontStyle === "Default" ? "Comic" : `${data.fontStyle}`;
  };

  const closePopUp = () => {
    theme.updateCloneData(
      {
        bgimage: "",
        logoImage: "",
        subDomain: "",
        primaryColor: "",
        secondaryColor: "",
        fontStyle: "Default",
        logoUrl: "",
        swapButtonColor: "",
      },
      false
    );
    setPrevCloneData();
    setClonePopUp(false);
    document.getElementsByTagName("body")[0].style.fontFamily = "";
  };
  return (
    <footer>
      {clonePopup && (
        <CloneForm
          close={closePopUp}
          setCloneData={setCloneData}
          prevCloneData={prevCloneData}
          dark={theme.isDark}
        />
      )}
      {aboutPopup && (
        <About close={() => setAboutPopUp(false)} dark={theme.isDark} />
      )}

      <div className="footertop"></div>

      <div className="footerbottom">
        <img className="leftimg" src="imgs/left-footer.png" alt="" />
        <div className="twolist">
          <ul className="list-nav1">
            {/* {!theme.isSubdomain && (
              <li>
                <a href="#" onClick={() => setClonePopUp(true)}>
                  {prevCloneData ? "Continue Clone" : "Free license"}
                </a>
                <span className="column"></span>
              </li>
            )} */}
            <li>
              <a href="#" onClick={() => setClonePopUp(true)}>
                {prevCloneData ? "Continue Clone" : "Free license"}
              </a>
              <span className="column"></span>
            </li>
            <li>
              <a href="#">Upgrade to v2</a>
              <span className="column"></span>
            </li>
            <li>
              <a href="#">100% gas and fees reimbursement</a>
            </li>
            {/* <li>
              <a href="#">Github</a>
              <span className="column"></span>
            </li>
            <li>
              <a href="#">Telegram</a>
              <span className="column"></span>
            </li>
            <li>
              <a href="#">Reddit</a>
              <span className="column"></span>
            </li>

            <li>
              <a href="#">Twitter</a>
            </li> */}
          </ul>

          <ul className="list-nav2">
            {/* <li>
              Affiliate of <img src="imgs/footer-1inch.png" alt="" />{" "}
              <span className="column"></span>
            </li> */}
            <li>Powered by Atom Foundation:</li>
            <li>
              <a href="#">Jointer.io</a>
              <span className="column"></span>
            </li>
            <li>
              <a href="#">BSCswap.com</a>
              <span className="column"></span>
            </li>

            <li>
              <a href="#">SmartSwap.exchange</a>
              <span className="column"></span>
            </li>
            <li>
              <a href="#">ZERO/1</a>
              <span className="column"></span>
            </li>
            <li>
              <a href="#">ElementZero.network</a>
              <span className="column"></span>
            </li>
            <li>
              <a href="#">Packey.insure</a>
              <span className="column"></span>
            </li>
            <li>
              <a href="#">Mass.investments</a>
            </li>
          </ul>
        </div>
        <div className="twologos">
          <a href="#">
            <img
              id="blackimg"
              src={
                !theme.isDark
                  ? "imgs/ethereumblack.png"
                  : "imgs/ethereumblack_dark.png"
              }
              alt=""
            />
          </a>
          <a href="#">
            {" "}
            <img src="imgs/binance.png" alt="" />
          </a>
        </div>
      </div>
    </footer>
  );
};
