import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { NumberContext } from "../api";
// let NumberContext = React.createContext();
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./../config/theme";
import { GlobalStyles } from "./../config/global";
import { ThemeContext } from "../contexts/ThemeContext";

export const Header = (props) => {
  const theme = useContext(ThemeContext);

  useEffect(() => {
    logout();
  }, []);
  const logout = () => {
    let dotspan = document.querySelector(".dotspan");

    let connectwallet = document.querySelector(".connectwallet");

    let login = document.querySelector(".login");
    let menuRight = document.querySelector(".right-sidemenu");

    let presalesmartswap = document.querySelector(".presalesmartswap");

    dotspan.onclick = function (e) {
      e.stopPropagation();
      menuRight.classList.add("slide");
      document.documentElement.style.overflow = "hidden";
      login.classList.remove("show");
      connectwallet.classList.remove("hide");
      dotspan.classList.remove("active");

      presalesmartswap.style.display = "block";
    };
  };
  return (
    <div className="header">
      <div className="header__logo">
        {theme.cloneData.logoImage ? (
          <>
            <a href={theme.cloneData.logoUrl}>
              <img
                src={
                  theme.isDark
                    ? theme.cloneData.logoImage
                    : theme.cloneData.bgimage
                }
                width="70%"
                alt=""
                style={{ margin: 10 }}
              />
            </a>
          </>
        ) : (
          <img src="imgs/right-logo.png" alt="" />
        )}

        {/* <img src="imgs/right-logo.png" alt="" /> */}
      </div>
      <div className="header__info">
        <a target="_blank" href="#">
          PDO live
        </a>
      </div>

      <div className="header__title">
        <h1>One-click cross-chain liquidity aggregators umbrella</h1>
      </div>
      <div className="header__control">
        <div href="#" className="right-menu-btn">
          <a
            className="presalesmartswap"
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSeZHeIaun7mXOs2NitLoXRJRc4J0ZvKHTcmXG6ivIqsBt6RkA/viewform"
          >
            DegenSwap PreSale
          </a>
          <a href="#" className="connectwallet primary-btn small">
            Connect Wallet
          </a>
          <div className="login">
            <p className="firstp">
              12.356BNB
              <img src="imgs/Layer-5443.png" alt="" />
            </p>

            <p className="btntomenu"> 0X291a...H1RS </p>
          </div>
          <span className="dotspan"></span>
        </div>
      </div>
    </div>
  );
};
