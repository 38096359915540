// import React, { useState } from 'react';

// import { ThemeProvider } from 'styled-components';
// import { lightTheme, darkTheme } from './config/theme';
// import { GlobalStyles } from './config/global';

// function App() {
//   const [theme, setTheme] = useState('light');

//   // The function that toggles between themes
//   const toggleTheme = () => {
//     // if the theme is not light, then set it to dark
//     if (theme === 'light') {
//       setTheme('dark');
//       // otherwise, it should be light
//     } else {
//       setTheme('light');
//     }
//   }
// return (
//   <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
//     <>
//       <GlobalStyles />
//       <button onClick={toggleTheme} >Toggle theme</button>
//       <h1>It's a light theme!</h1>
//       <footer>
//       </footer>
//     </>
//   </ThemeProvider>
// );
// }

// export default App;
import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { NumberContext } from "./api";
import { Display } from "./screens/display";
import { Home } from "./screens/home";
import { useHistory } from "react-router-dom";
import { Test } from "./components/Test";
import axios from "axios";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./config/theme";
import { GlobalStyles } from "./config/global";
import { ThemeContext } from "./contexts/ThemeContext";
// const NumberContext = React.createContext();

export default function App() {
  const { Provider, Consumer } = React.createContext();
  // const [theme, setTheme] = useState("light");
  const theme = useContext(ThemeContext);
  const [cloneData, setCloneData] = useState();
  const [isloading, setIsloading] = useState(false);
  const [isSubdomain, setIsSubdomain] = useState(false);
  const [noSubDomain, setNoSubDomain] = useState(false);
  const [subDomainName, setSubDomainName] = useState("");
  const [lightThemeData, setLightThemeData] = useState(lightTheme);

  const getData = async (domain) => {
    setIsloading(true);
    try {
      const res = await axios.get(
        `http://182.72.203.245:1830/api/v1/subdomain/getSubDomain/${domain[0]}`
      );
      console.log(res);
      if (res.data.response_code === 200) {
        // const r = document.querySelector(":root");
        // r.style.setProperty("--text-color", res.data.result.primaryColors);
        // r.style.setProperty(
        //   "--button-bg-color",
        //   res.data.result.seconderyColor
        // );
        // r.style.setProperty(
        //   "--req-bg",
        //   `url(${res.data.result.backGroundImage})`
        // );
        const finalData = {
          bgimage: res.data.result.backGroundImage,
          logoImage: res.data.result.logo,
          subDomain: res.data.result.subDomain,
          primaryColor: res.data.result.primaryColors,
          secondaryColor: res.data.result.seconderyColor,
          fontStyle: res.data.result.fontStyle,
          backgroundOverLay: res.data.result.backgroundOverLay,
          swapButtonColor: res.data.result.swapButton,
          logoUrl: res.data.result.logoUrl,
        };
        setCloneData(finalData);
        theme.updateCloneData(finalData, false);
        document.getElementsByTagName("body")[0].classList.add("subdomain");
      } else if (res.data.response_code === 404) {
        setNoSubDomain(true);
      }
      setIsloading(false);
    } catch (error) {
      console.log(error.response);
      setIsloading(false);
    }
  };

  useEffect(() => {
    const href = window.location.href;
    const domaindata = href
      .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
      .split("/")[0];
    const domain = domaindata.split(".");

    console.log(domaindata);
    setSubDomainName(domain[0]);

    const localhost = domaindata.indexOf("localhost");
    console.log(domain.length);
    if (domain.length === (localhost === -1 ? 3 : 2)) {
      theme.updateSubDomain(true);
      getData(domain);
    } else {
      document.getElementsByTagName("body")[0].classList.remove("subdomain");
      theme.updateSubDomain(false);
      setIsloading(false);
    }
  }, []);
  // console.log("updated data", {
  //   cloneData,
  //   isloading,
  //   isSubdomain,
  //   noSubDomain,
  //   subDomainName,
  // });

  useEffect(() => {
    // console.log("clone data", cloneData);
    if (cloneData) {
      setLightThemeData({
        ...lightThemeData,
        hero_bg: cloneData.primaryColor,
        popup_after: cloneData.primaryColor,
        footer_bg: cloneData.secondaryColor,
        footer_bg1: cloneData.secondaryColor,
        primary_bg: cloneData.primaryColor,
        swapButtonColor: cloneData.swapButtonColor,
      });
    }
  }, [cloneData]);

  useEffect(() => {
    // console.log("Preview data", theme.preview);
    if (theme.preview) {
      setLightThemeData({
        ...lightThemeData,
        hero_bg: theme.cloneData.primaryColor,
        popup_after: theme.cloneData.primaryColor,
        footer_bg: theme.cloneData.secondaryColor,
        footer_bg1: theme.cloneData.secondaryColor,
        primary_bg: theme.cloneData.primaryColor,
        swapButtonColor: theme.cloneData.swapButtonColor,
      });
      document.getElementsByTagName("body")[0].classList.add("subdomain");
    } else {
      if (cloneData) {
        setLightThemeData({
          ...lightThemeData,
          hero_bg: cloneData.primaryColor,
          popup_after: cloneData.primaryColor,
          footer_bg: cloneData.secondaryColor,
          footer_bg1: cloneData.secondaryColor,
          primary_bg: cloneData.primaryColor,
          swapButtonColor: cloneData.swapButtonColor,
        });
      } else {
        setLightThemeData({
          ...lightTheme,
        });
      }
      document.getElementsByTagName("body")[0].classList.remove("subdomain");
    }
  }, [theme.preview, theme.cloneData]);
  // console.log("light theme data", lightThemeData);
  return (
    <ThemeProvider theme={!theme.isDark ? lightThemeData : darkTheme}>
      <>
        <GlobalStyles />
        {isloading ? (
          <p>Loading</p>
        ) : (
          <Router>
            <Switch>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/notfound">
                <NotFound />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </Router>
        )}
      </>
    </ThemeProvider>
  );
}

function About() {
  return <h2>About</h2>;
}

function NotFound() {
  return (
    <>
      <div
        id="notfoundscreen"
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{ alignItems: "center", fontFamily: "comic", fontSize: 40 }}
        >
          <Test />
          <h1 align="center">404</h1>
          <p>Page Not Found</p>
        </div>
        <br />
      </div>
      <footer></footer>
    </>
  );
}
